@keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
}

.Explore{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-width: 100vw;
    min-height: 100vh;
    background-color: #2C2C2C;
}

.RetPopupContainer{
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 75%;
    border-radius: 1rem;
    padding: 2rem;
    box-sizing: border-box;
    background-color: #2C2C2C;
    margin-left: 14vw;
}

.RetPopupContainer .input{
    display: flex;
    flex: 1;
    outline: none;
    width: 100%;
    align-self: center;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: start;
    vertical-align: top;
    border-radius: 1rem;
    padding: 1rem;
    box-sizing: border-box;
    text-align: left;
    vertical-align: top;
    overflow-wrap: break-word;
    word-wrap: break-word;
    resize: none;
    font-size: 1.1rem;
}

.RetPopupContainer .RetPopupButton{
    display: flex;
    width: 100%;
    height: 3rem;
    background-color: #f36934;
    border-radius: 1rem;
    color: white;
}

.PaintingPopupContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
    height: 35%;
    border-radius: 1rem;
    padding: 2rem;
    box-sizing: border-box;
    background-color: #2C2C2C;
    margin-left: 14vw;
    animation: fadeIn 0.3s ease-out;
}

.PaintingPopupContainer .PaintingPopupButton{
    display: flex;
    width: 30%;
    height: 40px;
    background-color: #f36934;
    border-radius: 1rem;
    color: white;
}

.PaintingPopupCheckboxContainer {
    display: flex;
    flex-direction: column;
    gap: 5px; 
}

.ExploreContainer{
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 80%;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.ExploreInfoContainer{
    display: flex;
    flex-direction: column;
    width: 70vw;
    height: 85vh;
    background-color: #383636;
    border-radius: 2rem;
    box-sizing: border-box;
    padding: 2rem;
    padding-top: 1.5rem;
}

.angleRightOption {
    align-self: center;
    color: white;
}

.angleRightOption:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.dateText{
    color: white;
    font-size: 1.4rem;
}

.ExploreRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#exploreTitleContainer {
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
}

#exploreTitleContainer button {
    all: unset;
    color: #fafafa;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
}

#exploreTitleContainer button:hover {
    scale: 1.1;
}

.backIcon{
    font-size: 1.5rem;
    align-self: center;
    cursor: pointer;
}

.ExploreTitleText{
    display: flex;
    color: white;
    font-size: 1.5rem;
    display: flex;
    flex: 1;
}

.approveButton{
    all: unset;
    display: flex;
    background-color: #f36934;
    align-items: center;
    justify-content: center;
    align-self: center;
    border-radius: .7rem;
    width: 8rem;
    height: 2rem;
    color: white;
    font-weight: bold;
    font-size: 0.8rem;
    cursor: pointer;
}

.approveButton:disabled{
    background-color: #666666;
    opacity: 0.5;
}

.approveButton:disabled:hover{
    cursor: not-allowed;
}

.optionText{
    font-size: 1rem;
    user-select: none;
}

.photoContainer{
    display: flex;
    width: 20%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.photoTitle{
    font-size: 1.1rem;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}

.scrollViewExplore{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

}

#export-button{
    width: 8rem;
    height: 2.3rem;
}



.popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 8rem;
    background-color: #2c2c2c;
    z-index: 1000;
    position: absolute;
    /* border: 1px solid red;  */
}

.popup iframe {
    border-bottom: 1px solid grey !important;
}
  
.closeButton {
    height: 2.2rem;
    width: 100%;
    align-self: center;
    background-color: #F36934; /* Cor de fundo do botão */
    color: #fff; /* Cor do texto do botão */
    border: none;
    cursor: pointer;
    border-radius: 0.625rem;
}

.closeButton:hover {
    background-color: #C25429; /* Cor de fundo do botão ao passar o mouse */
}

.options_explore{
    position: absolute;
    display: flex;
    flex-direction: column;
    padding-top: 3px;
    gap: 3px;
}

.optButtons_Explore {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* border: 1px solid blue; */
}

.checkbox-container {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    cursor: pointer;
}

.checkbox-container input[type="checkbox"] {
    appearance: none;
    width: 15px;  /* Usei 20px para um círculo perfeito */
    height: 15px;
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 20%;  /* 50% para bordas arredondadas */
    transition: background-color 0.2s ease, border-color 0.2s ease;
}
  
.checkbox-container input[type="checkbox"]:checked {
    background-color: #f36934;
    border-color: #f36934;
    position: relative;
}
  
.checkbox-container input[type="checkbox"]:checked::after {
    content: '✔';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 14px;
}
  
.checkbox-container input[type="checkbox"]:focus {
    outline: none;
    border-color: #ff6f61;
}
  

@media (min-width: 420px) and (max-width: 1000px) {
    .ExploreContainer{
        margin-left: 4rem;
    }
}

@media (max-width: 760px) {
    #export-button {
        width: 40px;
    }
}