.reportInputContainer{
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 3rem;
    border-radius: 1rem;
    background-color: white;
    align-items: center;
    padding-left: .5rem;
}

.reportInput{
    display: flex;
    border-width: 0;
    width: 100%;
    height: 2rem;
    outline: none;
    border-radius: 1rem;
    padding-left: 0.5rem;
    border-color: #fff;
    font-size: .8rem;
}

.Report{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-color: #2C2C2C;
}


.line_reports{
  display: flex;
  height: 4px;
  width: 100%;
  background-color: white;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.ReportInfoContainer{
    display: flex;
    flex-direction: column;
    width: 65vw;
    height: 85vh;
    padding: 3rem;
    box-sizing: border-box;
    background-color: #383636;
    border-radius: 2rem;
}

.item_Report{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    color: #F5F5F5;
    height: 100%;
    width: 100%;
  }