.Management {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #2C2C2C;
    width: 100%;
    height: 100%;
    min-width: 100vh;
    min-height: 100vh;
}

.mainManagmentContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #2C2C2C;
    justify-content: center;
}

.leftContainer{
    display: flex;
    flex-direction: column; 
    height: 100%;
    width: 45%;
}

.rightContainer{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 45%;
    align-items: center;
}

.lineList{
    display: flex;
    padding: .05rem;
    margin-bottom: .2rem;
    margin-top: .2rem;
    background-color: white;
}

.companyContainer{
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    height: 100%;
}

.topButtonsContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 2vh;
}

.topButtonEnabled{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: gray;
    width: 32%;
    height: 3.5rem;
    border-radius: 1rem;
    cursor: pointer;
}

.topButtonDisabled{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #383636;
    width: 32%;
    height: 3.5rem;
    border-radius: 1rem;
    cursor: pointer;
}

.managementInputContainer{
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 3rem;
    border-radius: 1rem;
    background-color: white;
    align-items: center;
}

.managementInput{
    display: flex;
    border-width: 0;
    width: 100%;
    height: 2rem;
    outline: none;
    border-radius: 1rem;
    padding-left: 0.5rem;
    border-color: #fff;
    font-size: .8rem;
}

.searchIcon{
    font-size: 1.5em;
    padding-left: 0.5em;
    align-self: center;
}

.listContainer{
    display: flex;
    flex-direction: column;
    background-color: #383636;
    height: 35rem;
    border-radius: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.managementListContainer{
    display: flex;
    flex-direction: column;
    height: 100%;
    
    overflow-y: auto;  /* Isso permite a rolagem vertical */
    overflow-x: hidden;  /* Isso permite a rolagem vertical */
    scrollbar-width: thin; /* "thin" ou "auto" ou "none" */
    scrollbar-color: #888 #383636; /* thumb and track color */
}

.managementListContainer::-webkit-scrollbar {
    width: 2px;
}
  
.managementListContainer::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}

.itemKey{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: 'pointer'
}

.whiteText{
    color: white;
}

.buttonColumn{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.agendaColumn{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 20rem;
}

.infoButtonRight{
    display: flex;
    cursor: pointer;
    font-size: 1rem;
    width: 1rem;
}

.infoButtonRight:hover{
    font-size: 1.2rem;
}

.column1Button{
    display: flex;
    flex-direction: column;
    background-color: #383636;
    border-radius: 1rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 7rem;
}

.pizzaButton{
    display: flex;
    flex-direction: row;
    background-color: #383636;
    width: 49%;
    height: 13rem;
    border-radius: 1rem;
}

.pizzaContent{
    display: flex;
    flex-direction: column;
    background-color: #383636;
    width: 100%;
    height: 13rem;
    border-radius: 1rem;
}

.labelCharts{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 4%;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 50%;
}

.labelCharts2{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 4%;
    padding-top: 15%;
    padding-bottom: 15%;
}

.labelText_Management{
    font-size: calc(7px + .5vw);
    color: white;
}

.donutButton{
    display: flex;
    flex-direction: row;
    background-color: #383636;
    border-radius: 1rem;
    width: 30%;
    height: 13rem;
}

.lineGraphButton{
    display: flex;
    flex-direction: column;
    background-color: #383636;
    width: 100%;
    border-radius: 1rem;
    height: 15rem;
}

.registrosButton{
    display: flex;
    flex-direction: column;
    background-color: #383636;
    width: 45%;
    border-radius: 1rem;
    height: 19.5rem;
}

.agendaButton{
    display: flex;
    flex-direction: column;
    background-color: #383636;
    width: 53%;
    border-radius: 1rem;
    height: 19.5rem;
}

.userContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #383636;
    height: 12vh;
    border-radius: 1rem;
    cursor: pointer;
    padding: 1rem;
}

.userContainer:hover{
    background-color: #888;
}

.reportContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #383636;
    height: 9vh;
    border-radius: 1rem;
    cursor: pointer;
    padding: 1rem;
}

.reportContainer_disabled {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #383636;
    height: 9vh;
    border-radius: 1rem;
    cursor: not-allowed;
    padding: 1rem;
    opacity: 0.5;
}

.buttonText{
    display: flex;
    flex-direction: column;
    align-self: center;
    color: white;
    text-align: center;
    font-size: calc(7px + .5vw);
}

.reportContainer:hover{
    background-color: #888;
}

.userText{
    padding: 1rem;
    color: white;
}

.userIcon{
    align-self: center;
    color: white;
    font-size: 2.5rem;
    padding: .7rem;
    padding-left: 2rem;
}

.infoIcon{
    align-self: center;
    color: white;
    font-size: 1.5rem;
    padding: .7rem;
    padding-left: 2rem;
}

.chart-container{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.management-calendar-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 1rem;
    box-sizing: border-box;
    border: 1px solid #333;
    font-family: Arial, sans-serif;
    color: white;
    padding-top: 0;
}

.accessOptionsRight{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #383636;
    width: 100%;
    height: 40.7rem;
    border-radius: 1rem;

    box-sizing: border-box;
    padding: 2.5rem;
    padding-top: 0;
}

.checkListText{
    display: flex;
    font-size: .8rem;
    color: white;
    justify-content: center;
    width: 16rem;
    font-family: Arial, Helvetica, sans-serif;
}

.sendButton{
    display: flex;
    flex-direction: column;
    background-color: orangered;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 2rem;
    border-radius: 1rem;
    padding: .3rem;
    cursor: pointer;
}

.sendButton_disabled{
    display: flex;
    flex-direction: column;
    background-color: orangered;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 2rem;
    border-radius: 1rem;
    padding: .3rem;
    cursor: not-allowed;
    opacity: 0.5;
}

.userBlockContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-between;
}

.checkListContainer{
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.checkListContainer2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    overflow-y: auto;  /* Isso permite a rolagem vertical */
    overflow-x: hidden;  /* Isso permite a rolagem vertical */
    scrollbar-width: thin; /* "thin" ou "auto" ou "none" */
    scrollbar-color: #888 #383636; /* thumb and track color */
}

@media (max-width: 600px) {
    .mainManagmentContainer{
        flex-direction: column;
        align-items: center;
        width: 60%;
    }
    .leftContainer{
        width: 100%;
    }
    .rightContainer{
        flex-direction: column;
        width: 100%;
    }
    .buttonColumn{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .topButtonsContainer{
        flex-direction: column;
    }
    .topButtonEnabled{
        width: 100%;
        margin-bottom: 10px;
    }
    .topButtonDisabled{
        width: 100%;
        margin-bottom: 10px;
    }
    .accessOptionsRight{
        width: 100%;
    }
    .pizzaButton{
        width: 100%;
    }
    .donutButton{
        width: 100%;
    }
    .lineGraphButton{
        width: 100%;
    }
    .agendaButton{
        display: flex;
        width: 100%;
        height: 100%;
        margin-top: 1.3rem;
    }
    .registrosButton{
        width: 100%;
    }
    .column1Button{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .accessOptionsRight{
        width: 100%;
        padding: 0;
    }
    .checkListText{
        padding: 1rem
    }
    .checkListContainer{
        padding: .5rem
    }
    .checkListContainer2{
        padding: .5rem
    }
    .checkListText{
        font-size: 10px;
    }
    .accessOptionsRight{
        height: 100%;
    }
    .sendButton{
        padding: 0rem;
        margin-bottom: 1rem;
        width: 80%;
        align-self: center;
    }
    .agendaColumn{
        flex-direction: column;
        height: 60vh;
    }
    .registrosButton{
        flex-direction: column;
        display: flex;
    }
}
