/* src/App.css */

.Register {
	display: flex;
	height: 100vh;
	width: 100vw;
	justify-content: space-evenly;
	background-color: #2C2C2C;
	justify-content: center;


}

.companyText {
	color: white;
	font-size: 1.5rem;
	align-self: center;
	font-weight: bold;
}

.companyText2 {
	color: #F5F5F5;
	width: 29rem;
	margin-left: 2.3rem;
	padding: 0.625rem;
	align-self: flex-start;
	margin-bottom: 1.25rem;
	font-size: 0.75rem;
	font-weight: bold;

}

.content-inspector {
	display: flex;
	align-items: center;



}

.form-container-inspector {
	display: flex;
	align-self: center;
	flex-direction: column;
	background-color: #383636;
	border-radius: 0.625rem;
	align-items: center;

	width: 45%;
	height: 90%;
	max-height: 50rem;
	padding: 2rem;
	box-sizing: border-box;

	overflow-y: auto;
	/* Isso permite a rolagem vertical */
	overflow-x: hidden;
	/* Isso permite a rolagem vertical */
	scrollbar-width: thin;
	/* "thin" ou "auto" ou "none" */
	scrollbar-color: #888 #383636;
	/* thumb and track color */
}

/* Estiliza a barra de rolagem para navegadores Webkit (Chrome, Safari, etc.) */
.form-container-inspector::-webkit-scrollbar {
	width: 4px;
}

.form-container-inspector::-webkit-scrollbar-thumb {
	background-color: #888;
	border-radius: 4px;
}

.form-container-inspector::-webkit-scrollbar-thumb:hover {
	background-color: #747070;
}

.inspector {

	width: 28rem;
	/* Ajuste conforme necessário para diferentes tamanhos de tela */
	height: 1.5rem;
	/* Ajuste conforme necessário para diferentes tamanhos de tela */
	max-width: 100%;
	/* Garante que o input não ultrapasse a largura do contêiner pai */
	margin-bottom: 0rem;
	/* Espaçamento inferior */
	background-color: #FFFFFF;
	border-radius: 1rem;
	/* Bordas arredondadas */
	color: black;
	margin-top: 1.5rem;
	/* Espaçamento superior */
	font-size: 0.8rem;
	/* Tamanho da fonte base em 'rem' para elementos internos */
	padding: 0.5rem 0.7rem;
	/* Espaçamento interno */
}

.inspector::placeholder {
	color: #A4A2A2;
	/* Cor do placeholder */
}



.button-inspector {
	width: 85%;
	height: 100%;
	background-color: #F36934;
	/* Cor de fundo do botão */
	color: #fff;
	/* Cor do texto do botão */
	border: none;
	cursor: pointer;
	border-radius: 0.625rem;
	margin-top: 1.5rem;
	align-items: center;
}

.button-inspector:hover {
	background-color: #C25429;
	/* Cor de fundo do botão ao passar o mouse */
}

body.inspector {
	margin: 0;
	padding: 0;
}

label.inspector {
	display: block;
	/* Define as labels como blocos para que ocupem toda a largura disponível */
	text-align: right;
	/* Alinha o texto à direita */
	margin-bottom: 0.313rem;
	/* Adiciona um espaço entre a label e o input */
	font-size: 0.75rem;
	color: #F5F5F5;
}



a.inspector {
	color: #F5F5F5
}

@media(max-width:350px) {

	.text-inspector {
		font-size: 1.3rem;
		margin-left: 0.1rem;


	}

	.text-inspector2 {
		font-size: 0.6rem;
		margin-left: 0.1rem;



	}

	.form-container-inspector {
		width: 10rem;
		align-content: start;


	}

	.button-inspector {
		width: 9rem;


	}

	.inspector {
		width: 9rem;
		margin-left: 0;
	}

}

@media ((min-width:350px) and (max-width: 550px)) {


	.text-inspector {
		font-size: 1rem;
		margin-left: 0.6rem;



	}

	.text-inspector2 {
		font-size: 0.5rem;
		margin-left: 0.6rem;



	}

	.form-container-inspector {
		width: 16rem;
		align-content: start;
	}

	.button-inspector {
		width: 13.5rem;


	}

	.inspector {
		width: 13.5rem;
	}


}

@media (min-width: 550px) and (max-width: 1130px) {
	.text-inspector {
		font-size: 1.5rem;
		padding: 0rem;
		margin-left: 0.5rem;


	}

	.text-inspector2 {
		font-size: 0.6rem;
		padding: 0rem;
		margin-left: 0.5rem;

	}

	.form-container-inspector {
		width: 20rem;
		align-content: start;
	}

	.button-inspector {
		width: 19rem;


	}

	.inspector {
		width: 19rem;
	}
}