.SelectReportContainer{
    display: flex;
    flex-direction: column;
    width: 80%;
    flex: .8;
    background-color: #383636;
    border-radius: 1rem;
    padding: 2.5rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    box-sizing: border-box;
}

.SelectReportContainer .HeaderContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: .10;
    align-items: center;
}

.SelectReportContainer .TextStyle{
    font-size: 1.5rem;
    color: white;
}

.SelectReportContainer .MainBoxesContainer{
    display: flex;
    width: 100%;
    flex: .47;
    justify-content: space-between;
}

.SelectReportContainer .MainBox{
    display: flex;
    flex-direction: column;
    width: 47%;
    height: 100%;
    background-color: #717171;
    border-radius: 1rem;
    cursor: pointer;
    justify-content: space-evenly;
    align-items: center;
    user-select: none;
}

.SelectReportContainer .MainBox:hover{
    background-color: #D9D9D9;
    opacity: .9;
}

.SelectReportContainer .MainBox:hover .TextStyle{
    color: gray;
}

.SelectReportContainer .iconStyle{
    color: white;
}

.SelectReportContainer .MainBox:hover .iconStyle{
    color: gray;
}

