#container {
    background-color: #2c2c2c;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
}

#content {
    width: 60%;
    max-width: 768px;
    /* border: 1px solid red; */
    text-align: justify;
}

#title {
    color: #f5f5f5;
    font-size: 32px;
    text-align: center;
}

.focus-text {
    color: #f5f5f5;
}

#text {
    line-height: 25px;
    color: gray;
}

#content input {
    border: 1px solid #181818;
    padding: 5px;
    width: 60%;
    height: 40px;
    border-radius: 10px;
    background-color: #474747;
    color: #f5f5f5;
}

#content input:focus {
    outline: 2px solid #f77b4a;
    border: 1px solid #F36934;
}

#content button {
    all: unset;
    background-color: #F36934;
    height: 30px;
    width: 100px;
    border-radius: 10px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    cursor: pointer;
    transition: 0.3s;
    color: #f5f5f5;
    font-size: 12px;
}

#content button:hover {
    background-color: rgb(250, 97, 37);
}

#modal-container {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    color: #f5f5f5;
}

#modal-content {
    background-color: #383636;;
    width: 50%;
    max-width: 500px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#modal-content > p {
    width: 70%;
}

#modal-footer {
    all: unset;
    text-align: center;
    width: 98%;
    border-top: 1px solid grey;
    padding: 5px;
    cursor: pointer;
    height: 40px;
    transition: 0.3s;
}

#modal-footer:hover {
    background-color: rgba(0, 0, 0, 0.1);
}