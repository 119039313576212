/* src/App.css */

.RegisterMainContainer {
  display: flex;
  align-self: center;
  flex-direction: column;
  background-color: #383636;
  border-radius: 0.625rem;
  align-items: center;
  
  width: 50%;
  height: 85%;

  padding: 2rem;
  box-sizing: border-box;

  overflow-y: auto;  /* Isso permite a rolagem vertical */
  overflow-x: hidden;  /* Isso permite a rolagem vertical */
  scrollbar-width: thin; /* "thin" ou "auto" ou "none" */
  scrollbar-color: #888 #383636; /* thumb and track color */
}

/* Estiliza a barra de rolagem para navegadores Webkit (Chrome, Safari, etc.) */
.RegisterMainContainer::-webkit-scrollbar {
  width: 4px;
}

.RegisterMainContainer::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.RegisterMainContainer::-webkit-scrollbar-thumb:hover {
  background-color: #747070;
}

.company {
  width: 28rem; /* Ajuste conforme necessário para diferentes tamanhos de tela */
  height: 1.5rem; /* Ajuste conforme necessário para diferentes tamanhos de tela */
  max-width: 100%; /* Garante que o input não ultrapasse a largura do contêiner pai */
  margin-bottom: 0rem; /* Espaçamento inferior */
  background-color: #FFFFFF;
  border-radius: 1rem; /* Bordas arredondadas */
  color: black;
  margin-top: 1.5rem; /* Espaçamento superior */
  font-size: 0.8rem; /* Tamanho da fonte base em 'rem' para elementos internos */
  padding: 0.5rem 0.7rem; /* Espaçamento interno */
}

.company::placeholder {
color: #A4A2A2; /* Cor do placeholder */
}

.button-company {
  width: 85%;
  flex-shrink: 0;
  height: 3rem;
  background-color: #F36934; /* Cor de fundo do botão */
  color: #fff; /* Cor do texto do botão */
  border: none;
  cursor: pointer;
  border-radius: 0.625rem;
  margin-top: 1.5rem;
}

.button-company:hover {
background-color: #C25429; /* Cor de fundo do botão ao passar o mouse */
}

body.company {
margin: 0;
padding: 0;
}

label.company {
display: block; /* Define as labels como blocos para que ocupem toda a largura disponível */
text-align: right; /* Alinha o texto à direita */
margin-bottom: 0.313rem; /* Adiciona um espaço entre a label e o input */
font-size: 0.75rem;
color: #F5F5F5;
}


a.company {
color: #F5F5F5
}