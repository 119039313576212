.customButton{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
}

.customButtonIcon{
    display: flex;
    flex-direction: column;
    align-self: center;
    color: white;
    font-size: 1rem;
    padding: 1rem;
}

.customButtonText{
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 1rem;
    padding-right: 1rem;
}