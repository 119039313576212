.Register{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-width: 100vw;
    min-height: 100vh;
    background-color: #2C2C2C;
}

.RegisterContainer{
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 80%;
    margin-left: 15rem;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.RegisterInfoContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #383636;
    border-radius: 2rem;
    box-sizing: border-box;
    padding: 2rem;
}

.RegisterRow{
    display: flex;
    justify-content: center;
}

.backIcon_Register{
    font-size: 1.5rem;
    align-self: center;
    cursor: pointer;
    color: white
}

.dateText_Register{
    color: white;
    font-size: 1.5rem;
    align-self: center;
}

.approveButton_Register{
    display: flex;
    background-color: #f36934;
    align-items: center;
    justify-content: center;
    align-self: center;
    border-radius: .7rem;
    width: 8rem;
    height: 2.2rem;
    color: white;
    font-weight: bold;
    font-size: 0.8rem;
    cursor: pointer;
}

.approveButton_Register:disabled{
    background-color: #666666;
    opacity: 0.5;
}

.approveButton_Register:disabled:hover{
    cursor: not-allowed;
}

.optionText_Register{
    font-size: 1rem;
}

.photoContainer_Register{
    display: flex;
    width: 20%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.photoTitle_Register{
    font-size: 1.1rem;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}

button.arrow_Register {
    color: #F5F5F5; /* Cor do texto do botão */
    border: none; /* Remove a borda padrão do botão */
    width: 35px; /* Largura do botão (ajuste conforme necessário) */
    height: 40px; /* Altura do botão (ajuste conforme necessário) */
    cursor: pointer; /* Transforma o cursor em uma mão quando passa sobre o botão */
    font-size: 12px; /* Tamanho da fonte do texto (ajuste conforme necessário) */
    background-color: #383636;
    float: right;
    margin-right: 10px;
    margin-top: 15px;
}

.scrollView_Register{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    overflow-y: auto;  /* Isso permite a rolagem vertical */
    overflow-x: hidden;  /* Isso permite a rolagem vertical */
    scrollbar-width: thin; /* "thin" ou "auto" ou "none" */
    scrollbar-color: #2c2c2c #2c2c2c; /* thumb and track color */
}

::-webkit-scrollbar {
    width: 4px; /* Largura da barra de rolagem */
    padding: auto;
  }
  
  ::-webkit-scrollbar-track {
    background: #383636; /* Cor do track */
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #888; /* Cor do thumb */
    border-radius: 4px; /* Bordas arredondadas do thumb */
  }
  


button.arrow_Register:hover {
    background-color: #383636;
    color: #F5F5F5;
    cursor: pointer;
    transform: scale(1.1);
    opacity: 0.9;
}

.popup_Register {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 52%;
    left: 57%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 90%;
    background-color: #2c2c2c;
    z-index: 1000; 
}
  
.closeButton_Register {
    height: 2.2rem;
    width: 6rem;
    align-self: center;
    background-color: #F36934; /* Cor de fundo do botão */
    color: #fff; /* Cor do texto do botão */
    border: none;
    cursor: pointer;
    margin-top: 1.5rem;
    border-radius: 0.625rem;
}

.closeButton_Register:hover {
    background-color: #C25429; /* Cor de fundo do botão ao passar o mouse */
}

.options_Register{
    position: absolute;
    display: flex;
    flex-direction: column;
    padding-top: 3px;
    gap: 3px;
}

.optButtons_Register {
    display: flex;
    gap: 5rem;
}

@media (min-width: 420px) and (max-width: 1000px) {
    .RegisterContainer{
        margin-left: 4rem;
    }
}