
  .parent {
    display: flex;
    width: 100%;
    height: 10rem;
    margin-top: 25px;

    background: #ffffff;
    border-radius: 15px;
    padding: 1rem;
    box-sizing: border-box;
  }

  .file-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    position: relative;
    cursor: pointer;

    overflow: auto;
    scrollbar-width: thin; /* "thin" ou "auto" ou "none" */
    scrollbar-color: #888 #383636; /* thumb and track color */
  }

  .LogoImg {
    cursor: pointer;
    width: 25px;
  }

  .LoadImg{
    height: 6rem;
    padding-top: 2rem;
  }

  .file-upload p {
    width: 100%;
    font-size: 0.87rem;
    margin-top: 10px;
    color: #bbcada;
  }

  .file-upload input {
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
  }
