.DocumentContainer{
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 80%;
    border-radius: 1rem;
    background-color: #383636;
    padding: 3rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    box-sizing: border-box;
    overflow: auto;
}

.DocumentContainer .visualInspectionContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    opacity: .8;
}

.DocumentContainer .inspectionOption{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-right: 1rem;
    box-sizing: border-box;
    align-items: center;
}

.DocumentContainer .measurementOption{
    display: flex;
    flex-direction: column;
    width: 70%;
    align-self: center;
    padding-right: 1rem;
    box-sizing: border-box;
    align-items: center;
}

.DocumentContainer .measurementContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    opacity: .8;
}

.DocumentContainer .documentLogoButton{
    font-size: 1.5rem;
    color: white;
    cursor: pointer;
    user-select: none;
}

.DocumentContainer .documentLogoButton:hover{
    scale: 1.1;
}

.DocumentContainer .IndividualInspectionContainer{
    display: flex;
    justify-content: space-between;
    height: 4.5rem;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

.DocumentContainer .optionTextNew{
    font-size: calc(7px + .7vw);
    cursor: pointer;
    user-select: none;
}