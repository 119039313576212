.ExploreMainContainer {
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100%;
    background-color: #2C2C2C;
    overflow-y: hidden;
    overflow-x: hidden;
}


.content_List {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    width: 100%;
    height: 100%;
}

.content-wrapper_List {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #383636;
    border-radius: 10px;
    padding: 3rem;
    padding-left: 4.5rem;
    padding-right: 4.5rem;
    width: 60%;
    height: 80%;
}

.upperSide_List{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;  /* Isso permite a rolagem vertical */
    overflow-x: hidden;  /* Isso permite a rolagem vertical */
    scrollbar-width: thin; /* "thin" ou "auto" ou "none" */
    scrollbar-color: #888 #383636; /* thumb and track color */
}   

/* Estiliza a barra de rolagem para navegadores Webkit (Chrome, Safari, etc.) */
.upperSide_List::-webkit-scrollbar {
        width: 4px;
}

.upperSide_List::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 4px;
}

.upperSide_List::-webkit-scrollbar-thumb:hover {
        background-color: #383636;
}

.arrow_List {
    margin-top: 55px;
}

.map_List {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #F5F5F5;
    color: #383636;
    border-radius: 15px;
    width: 7rem;
    height: 3rem;
    margin-top: 1rem;
    align-self: center;
}

.map_List:hover {
    cursor: pointer;
    transform: scale(1.03);
    opacity: 0.9;
}

.icon_List {
    padding-left: 5px;
}

.inputContainer_List{
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 2.8rem;
    justify-content: space-between;
    border-radius: 1rem;
    background-color: white;
    align-items: center;
    align-self: flex-end;
}

.myinput_list{
    display: flex;
    border-width: 0;
    width: 100%;
    height: 2rem;
    outline: none;
    border-radius: 1rem;
    border-color: #fff;
    font-size: .8rem;
}

.search_List{
    display: none;
    position: relative;
    margin-top: 0.5rem;
    margin-bottom:2rem;
    width: 29.6rem;
    height: 5rem;
    font-size: 0.9rem; /* Tamanho da fonte base em 'rem' para elementos internos */
}

.search_List input{
    position: absolute;
    top:0;
    left: 0;
    width: 28rem;
    z-index: 1000;
    height: 1.5rem;
    cursor: pointer;
    border-radius: 1rem;
    padding: 0.5rem 0.7rem;
}

.itemKey_List{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: 'pointer';
    width: 57rem;
    margin-left: 1rem;
}

.line_List{
    display: flex;
    height: .1vh;
    margin-bottom: 1%;
    margin-top: 1%;
    background-color: white;
    width: 57rem;
    margin-left: 1rem;
}

.list_List{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 0.1vh;
    width: 100%;
    overflow-y: auto;  /* Isso permite a rolagem vertical */
    overflow-x: hidden;  /* Isso permite a rolagem vertical */
    scrollbar-width: thin; /* "thin" ou "auto" ou "none" */
    scrollbar-color: #888 #383636; /* thumb and track color */
}


