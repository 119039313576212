
.search{
    position: relative;
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: .5rem;
    height: 3rem;
    border-radius: 1rem;
    font-size: 0.9rem; 
}

.search input{
    position:absolute;
    width: 100%;
    top:0;
    left: 0;
    z-index: 90;
    height: 3.3rem;
    cursor: pointer;
    border-radius: 1rem;
    padding: 0.5rem 0.7rem;
    box-sizing: border-box;
}

.active::before{
    right: 1.45rem;
}
.active::after{
    right: 2rem;
}

.search .option1 {
    position: absolute;
    width: 100%;
    top: 4rem;
    max-height: 10rem;
    left: 0; /* Adicione esta linha para garantir que o elemento .search comece da borda esquerda */

    background-color: #fff;
    border-radius: 1rem;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    transition: 0.15s;
    box-shadow: 0 30px 30px rgba(0,0,0,0.09);
  
    
    overflow-y: auto;  /* Isso permite a rolagem vertical */
    overflow-x: hidden;  /* Isso permite a rolagem vertical */
    scrollbar-width: thin; /* "thin" ou "auto" ou "none" */
    scrollbar-color: #888 #888; /* thumb and track color */

}

.search .option1:-webkit-scrollbar {
    width: 4px;
    background-color: #888;
}

.search .option1::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}

.search .option1::-webkit-scrollbar-thumb:hover {
    background-color: white;
}

.active .option1{
    visibility: visible;
    opacity: 1;
    z-index: 100;
}

.search .option1 div{
        padding: 0.75rem;
        cursor: pointer;
}

.search .option1 div:hover{
        background-color: #f36934;
        color: #f5f5f5;
        border-radius: 1rem;
}

.searchbox_List{
        position: absolute;
        width: 55rem;
        height: 1.5rem;
        border-radius: 1rem;
        align-content: center;
        font-size: 0.9rem; /* Tamanho da fonte base em 'rem' para elementos internos */
}
    
.searchbox_List input{
        position: absolute;
        width: 35rem;
        z-index: 1000;
        height: 1.5rem;
        cursor: pointer;
        border-radius: 1rem;
        padding: 0.5rem 0.7rem;
}
    
.optionList {
        position: relative;
        width: 35rem;
        top: 4rem;
        max-height: 10rem;
        background-color: #fff;
        border-radius: 1rem;
        overflow: hidden;
        visibility: hidden;
        opacity: 0;
        transition: 0.15s;
        box-shadow: 0 30px 30px rgba(0,0,0,0.09);
        overflow-y: auto;  /* Isso permite a rolagem vertical */
        overflow-x: hidden;  /* Isso permite a rolagem vertical */
        scrollbar-width: thin; /* "thin" ou "auto" ou "none" */
        scrollbar-color: #888 #383636; /* thumb and track color */
}
/* Estiliza a barra de rolagem para navegadores Webkit (Chrome, Safari, etc.) */
.optionList:-webkit-scrollbar {
        width: 4px;
}

.optionList::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 4px;
}
.optionList::-webkit-scrollbar-thumb:hover {
        background-color: #383636;
}
    
.active .optionList{
        visibility: visible;
        opacity: 1;
        z-index: 100;
}
.optionList div{
        padding: 0.75rem;
        cursor: pointer;
}

.optionList div:hover{
        background-color: #f36934;
        color: #f5f5f5;
        border-radius: 1rem;

}
    


@media(max-width:350px)
{
    .search {
        width: 9rem;
        font-size: 0.8rem;
        margin-left: 0rem;
        right: 0.8rem;
     }
    .search .option1{
        width: 8rem;
        margin-left: 0rem;
    }
    .search input{
        width: 9rem;
        margin-left: 0rem;
        right: 0.8rem;
    }
    .search::after{
        right: 0.1rem;
    }
    .search::before{
        right: 0.5rem;
    }
    .active::after{
        right: 0.5rem;
    }  
    .active::before{
        right: 0.1rem;
    }
}
  
@media ((min-width:350px) and (max-width: 550px)){
    .search {
        width: 13.5rem;
        font-size: 0.8rem;
        margin-left: 0rem;
        right: 0.8rem;
    }
    .search .option1{
        width: 13.5rem;
    }
    .search input{
        width: 13.5rem;
        right:0.8rem;
    }
    .search::after{
        right: 0.1rem;
    }
    .search::before{
        right: 0.5rem;
    }
    .active::after{
        right: 0.5rem;
    }
    .active::before{
        right: 0.1rem;
    }

}
/*@media (min-width: 550px) and (max-width: 1130px) {
    .search {
        width: 19rem;
        font-size: 0.8rem;
        right: 0.8rem;
    }
    .search .option1{
        width: 19rem;
    }
    .search input{
        width: 19rem;
        right:0.8rem;
    }
    .search::after{
        right: 0.1rem;
    }
    .search::before{
        right: 0.5rem;
    }
    .active::after{
        right: 0.5rem;
    }
    .active::before{
        right: 0.1rem;
    }
}*/



.search-box{
    position: relative;
    width: 29.6rem;
    height: 1.5rem;
    border-radius: 1rem;
    align-content: center;
    font-size: 0.9rem; /* Tamanho da fonte base em 'rem' para elementos internos */
}

.search-box input{
    position:relative;
    width: 28rem;
    z-index: 1000;
    height: 1.5rem;
    cursor: pointer;
    border-radius: 1rem;
    padding: 0.5rem 0.7rem;
}

.optionMap {
    position: relative;
    width: 29.6rem;
    top: 4rem;
    max-height: 10rem;
    background-color: #fff;
    border-radius: 1rem;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    transition: 0.15s;
    box-shadow: 0 30px 30px rgba(0,0,0,0.09);
    overflow-y: auto;  /* Isso permite a rolagem vertical */
    overflow-x: hidden;  /* Isso permite a rolagem vertical */
    scrollbar-width: thin; /* "thin" ou "auto" ou "none" */
    scrollbar-color: #888 #383636; /* thumb and track color */

}
/* Estiliza a barra de rolagem para navegadores Webkit (Chrome, Safari, etc.) */
.optionMap:-webkit-scrollbar {
    width: 4px;
}
.optionMap::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}
.optionMap::-webkit-scrollbar-thumb:hover {
    background-color: #383636;
}
.active .optionMap{
    visibility: visible;
    opacity: 1;
    z-index: 100;
}
.optionMap div{
    padding: 0.75rem;
    cursor: pointer;
}

.optionMap div:hover{
    background-color: #f36934;
    color: #f5f5f5;
    border-radius: 1rem;
}



