.text-process{
  color:#F5F5F5;
  width: 29rem;
  margin-left: 2.3rem;
  padding: 0.625rem;
  margin-bottom: 1.25rem;
  font-size: 1.75rem;
  font-weight: bold;
  align-self: flex-start ;

}

.text-process2{
  color:#F5F5F5;
  width: 29rem;
  margin-left: 2.3rem;

  padding: 0.625rem;
  align-self: flex-start;
  margin-bottom: 1.25rem;
  font-size: 0.75rem;
  font-weight: bold;
}

.inputFloor{
  display: flex;
  width: 5rem;
  height: 85%;
  border-radius: .4rem;
  padding-left: 3%;
  padding-right: 2%;
  box-sizing: border-box;
}

.content-process {
  display: flex;
  align-items: center;
}  

.form-container-process {
  display: flex;
  align-self: center;
  flex-direction: column;
  background-color: #383636;
  border-radius: 0.625rem;
  align-items: center;

  width: 45%;
  height: 90%;
  max-height: 50rem;
  padding: 2rem;
  box-sizing: border-box;

  overflow-y: auto;  /* Isso permite a rolagem vertical */
  overflow-x: hidden;  /* Isso permite a rolagem vertical */
  scrollbar-width: thin; /* "thin" ou "auto" ou "none" */
  scrollbar-color: #888 #383636; /* thumb and track color */
}

/* Estiliza a barra de rolagem para navegadores Webkit (Chrome, Safari, etc.) */
.form-container-process::-webkit-scrollbar {
  width: 4px;
}

.form-container-process::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.form-container-process::-webkit-scrollbar-thumb:hover {
  background-color: #747070;
}

.process::placeholder {
color: #A4A2A2; /* Cor do placeholder */
}

.button-process {
  flex-shrink: 0;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1.5rem;
  background-color: #F36934; /* Cor de fundo do botão */
  color: #fff; /* Cor do texto do botão */
  border: none;
  cursor: pointer;
  border-radius: 0.625rem;
  margin-bottom: 0.625rem;
}

.button-process:hover {
  background-color: #C25429; /* Cor de fundo do botão ao passar o mouse */
}

body.process {
  margin: 0;
  padding: 0;
}

label.process {
  display: block; /* Define as labels como blocos para que ocupem toda a largura disponível */
  text-align: right; /* Alinha o texto à direita */
  margin-bottom: 0.313rem; /* Adiciona um espaço entre a label e o input */
  font-size: 0.75rem;
  color: #F5F5F5;
}

a.process {
  color: #F5F5F5
}