/* src/App.css */

.SignIn {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background-color: #2C2C2C;
}

.content2 {
	display: flex;
	align-items: center;
	justify-content: center;
	align-self: center;
	width: 80%;
	height: 90%;
	overflow: auto;
}

.form-container2 {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: #383636;
	border-radius: 10px;
	width: 40%;
	height: 77%;
	padding: 5%;
	padding-top: 2%;
	padding-bottom: 3%;
	box-sizing: border-box;
	overflow: auto;
	min-width: 300px;
}

.LoginPageResponsive{
	display: flex;
	width: 100%;
	height: 100%;
}

.map {
	display: flex;
	padding: 100px;
}

.downloadImages {
	display: flex;
	justify-content: center;
	margin: 0 5px;
	cursor: pointer;
}

.appimages {
	height: 77%;
	margin-right: 5%;
}

.appimages2 {
	display: none;
}


h1 {
	font-size: 24px;
	margin-bottom: 10px;
}

input.login {
	padding: 10px;
	margin-bottom: 10px;
	background-color: #FFFFFF;
	border-radius: 10px;
	color: black;
	margin-top: 15px;
}

input::placeholder {
	color: #A4A2A2;
	/* Defina a cor desejada para o placeholder */
}

.btnEntrarLogin {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 3rem;
	align-items: center;
	justify-content: center;
	background-color: #F36934;
	/* Cor de fundo do botão */
	color: #fff;
	/* Cor do texto do botão */
	cursor: pointer;
	border-radius: 10px;
	margin-top: 15px;
}

button:hover {
	background-color: #C25429;
	/* Cor de fundo do botão ao passar o mouse */
}

body {
	margin: 0;
	padding: 0;
}

label {
	display: block;
	/* Define as labels como blocos para que ocupem toda a largura disponível */
	text-align: right;
	/* Alinha o texto à direita */
	margin-bottom: 5px;
	/* Adiciona um espaço entre a label e o input */
	font-size: 12px;
	color: #F5F5F5;
}

img.logo {
	width: 70%;
	display: block;
	margin: 0 auto;
	margin-bottom: 50px;
}

img.mapImage {
	max-width: 100%;
	height: 90%;
	display: block;
	margin: 0 auto;
}

img.logoImageBig {
	height: 8rem;
	display: block;
}

img.appstoreImage {
	height: 60px;
	display: flex;
	margin: 0 auto;
}

img.playstoreImage {
	max-width: 100%;
	height: 60px;
	display: flex;
	margin: 0 auto;
}

a {
	color: #F5F5F5
}

.thirdRow {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.thirdRow p {
	padding: 0;
	margin: 0;
}

#error-msg {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.loading-circle {
	display: flex;
	flex-direction: column;
	border: 3px solid #f3f3f3;
	/* Cor de fundo */
	border-top: 3px solid #F36934;
	/* Cor da borda (parte do círculo que gira) */
	border-radius: 50%;
	width: 15px;
	height: 15px;
	animation: spin 2s linear infinite;
	align-self: center;
}

.loading-circle-splash {
	display: flex;
	flex-direction: column;
	border: 5px solid #f3f3f3;
	/* Cor de fundo */
	border-top: 5px solid #F36934;
	/* Cor da borda (parte do círculo que gira) */
	border-radius: 50%;
	width: 3rem;
	height: 3rem;
	animation: spin 2s linear infinite;
	align-self: center;
}


.forgotPasswordContainer{
	display: flex;
	flex-direction: column;
	width: 50vw;
	height: 80vh;
	padding: 5%;
	padding-top: 2%;
	padding-right: 2.5%;
	box-sizing: border-box;
	background-color: #383636;
	border-radius: 1rem;
	overflow: auto;
}

.inputForgot{
	display: flex;
	width: 100%;
	height: 2.5rem;
	border-radius: .5rem;
	padding-left: 1rem;
	box-sizing: border-box;
	outline: none;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}


@media (max-width: 1200px) {
	img.logoImageBig {
		height: 3rem;
		align-self: center;
	}

	.loading-circle-splash {
		width: 1.5rem;
		height: 1.5rem;
	}

	.appimages {
		padding: 0px;
		display: none;
	}

	img.mapImage{
		display: none;
		width: 0px;
		height: 0px;
	}

	.appimages2{
		display: flex;
		height: 15%;
		margin: 0%;
	}

	.content2 {
		padding-right: 0px;
		flex-direction: column;
	}
}